import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { HIDE_ALERT } from '../redux/actionTypes';

import Alert, { Container } from '@jetbrains/ring-ui-built/components/alert/alert';

const AlertContainer = ({ alerts, onHideAlert }) => {
  const onCloseAlertClick = (alert) => {
    const alertToClose = alerts.filter((it) => alert.key === it.key)[0];
    alertToClose.isClosing = true;
    window.setTimeout(() => onHideAlert(alert), 300);
  };

  return (
    <Container>
      {alerts.map((alert) => {
        const { message, key, ...rest } = alert;
        return (
          <Alert
            key={key}
            {...rest}
            onCloseRequest={() => onCloseAlertClick(alert)}
            onClose={() => onHideAlert(alert)}
          >
            {message}
          </Alert>
        );
      })}
    </Container>
  );
};

AlertContainer.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.object).isRequired,
  onHideAlert: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  onHideAlert: (alert) => dispatch({ type: HIDE_ALERT, payload: { alert } }),
});

const mapStateToProps = ({ alerts }) => ({ alerts });

export default connect(mapStateToProps, mapDispatchToProps)(AlertContainer);
