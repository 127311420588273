import { GlobusIcon } from '@rescui/icons';
import { Button } from '@rescui/button';
import React from 'react';
import PropTypes from 'prop-types';

function formatUrl(url) {
  return url.replace(/^https?:\/\/(.+?)\/?$/, '$1').replace('/', ' / ');
}

const VendorLink = ({ vendor: { name, url }, size }) => (
  <Button
    icon={<GlobusIcon />}
    mode="clear"
    size={ size || 'xs' }
    href={ url }
    target="_blank"
    rel="noreferrer"
  >
    { formatUrl(url) }
  </Button>
);

VendorLink.propTypes = {
  vendor: PropTypes.object,
  size: PropTypes.string,
};

export default VendorLink;
