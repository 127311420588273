import React from 'react';
import { useTextStyles } from '@rescui/typography';
import PropTypes from 'prop-types';
import cn from 'classnames';

export const KtorGeneratorBlurbText = () => {
  const textCn = useTextStyles();
  return (
    <>
      Download a project skeleton using the provided options and plugins below.
      If you would like to contribute your own plugins, you can publish to
      the <a className={textCn('rs-link')} href="https://github.com/ktorio/ktor-plugin-registry" target="_blank"
        rel="noreferrer">registry repository</a>.
    </>
  );
};

const KtorGeneratorBlurb = ({ compact, headerFontSize }) => {
  const textCn = useTextStyles();
  return (
    <div className={cn('control-panel__blurb', { compact })}>
      <h1 className={textCn('rs-h1')} style={{ fontSize: headerFontSize, margin: `${headerFontSize * 0.4}px 0` }}>New Ktor Project</h1>
      <p className={textCn('rs-text-2')} style={{ margin: `${headerFontSize * 0.4}px 0`, fontSize: Math.min(16, headerFontSize*0.5) }}>
        <KtorGeneratorBlurbText />
      </p>
    </div>
  );
};

KtorGeneratorBlurb.propTypes = {
  compact: PropTypes.bool,
  headerFontSize: PropTypes.number,
};

export default KtorGeneratorBlurb;

