import { Input } from '@rescui/input';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const artifactRegex = /([A-Za-z][A-Za-z0-9_-]*\.)+([A-Za-z][A-Za-z0-9_-]*)/;

function validateArtifact(artifact) {
  if (artifact.trim() === '') {
    return 'This is required';
  } else if (!/^([A-Za-z][A-Za-z0-9_-]*\.)+([A-Za-z][A-Za-z0-9_-]*$)/.test(artifact)) {
    return 'Should appear as "org.domain.artifact"';
  }
  return null;
}

const ArtifactInput = ({
  projectArtifact,
  setProjectArtifact,
  setProjectName,
}) => {
  const [artifactName, setArtifactName] = useState(projectArtifact || '');
  const [artifactError, setArtifactError] = useState(validateArtifact(artifactName));
  const setArtifactCallback = useCallback((e) => {
    const newValue = e.target?.value || '';
    const error = validateArtifact(newValue);
    setArtifactError(error);
    setArtifactName(newValue);
  });
  useEffect(() => {
    if (!artifactError && artifactName) {
      const match = artifactRegex.exec(artifactName);
      setProjectArtifact(artifactName);
      setProjectName(match[match.length - 1]);
    }
  }, [artifactName, artifactError]);

  return (<Input
    label="Project artifact"
    placeholder="com.example"
    onChange={setArtifactCallback}
    error={artifactError && <div className="error-message">{ artifactError }</div>}
    value={artifactName}
  />);
};

ArtifactInput.propTypes = {
  projectArtifact: PropTypes.string,
  setProjectArtifact: PropTypes.func,
  setProjectName: PropTypes.func,
};

export default ArtifactInput;
