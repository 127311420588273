import {
  LOAD_FEATURE_INFO,
  SHOW_ALERT,
  SHOW_FEATURE_INFO,
} from '../actionTypes';
import { trackError } from '../../constants/analytics';
import fetchApi from '../../services/fetchApi';
import { makeErrorAlert } from '../../services/helper';

export default function fetchFeatureInfo({ dispatch, getState }) {
  const loadFeatureInfo = (data) =>
    dispatch({ type: LOAD_FEATURE_INFO, payload: data });

  const onError = (errorMessage) =>
    dispatch({
      type: SHOW_ALERT,
      payload: { alert: makeErrorAlert(errorMessage) },
    });

  return (next) => (action) => {
    if (
      action.type === SHOW_FEATURE_INFO &&
      action.payload &&
      action.payload.id
    ) {
      const { docs, projectConfig } = getState();

      const featureId = action.payload.id;
      const version = projectConfig.ktorVersion || '2.3.10';
      const infoLoaded = docs[featureId] && docs[featureId].version === version;

      if (!infoLoaded) {
        fetchApi(`/feature/${featureId}/${version}/documentation`)
            .then((content) =>
              loadFeatureInfo({
                data: { version, content },
                id: featureId,
                version,
              }),
            )
            .catch(({ message, status }) => {
              trackError(`load-feature-doc-${status}`);
              loadFeatureInfo({ id: featureId, data: null });
              return onError(message || 'Something went wrong');
            });
      }
    }

    return next(action);
  };
}
