const KEY_PREFIX = 'ktor.generator.web';
const makeKey = (key) => [KEY_PREFIX, key].join(':');

const localStorage = {
  getItem: (key) => {
    try {
      return window.localStorage.getItem(makeKey(key));
    } catch (err) {
      return undefined;
    }
  },

  setItem: (key, value) => {
    try {
      return window.localStorage.setItem(makeKey(key), value);
    } catch (err) {
      return undefined;
    }
  },
};

export default localStorage;
