
/*
 * Injects directories into a (file, contents) map object.
 */
export function createDirectories(obj) {
  const result = {};

  for (const key in obj) {
    if (!Object.hasOwn(obj, key)) {
      continue;
    }
    const path = key.split('/');
    let current = result;
    for (let i = 0; i < path.length; i++) {
      if (i === path.length - 1) {
        current[path[i]] = obj[key];
      } else {
        current[path[i]] = current[path[i]] || {};
        current = current[path[i]];
      }
    }
  }

  return result;
}

/*
 * Removes directories with single directory children.
 */
export function flattenDirectories(obj) {
  const directorySingleChild = (node) => {
    if (typeof node !== 'object') {
      return false;
    }
    const entries = Object.entries(node);
    return entries.length === 1 && typeof entries[0][1] === 'object' && entries[0];
  };

  const flatten = (parent, key) => {
    let value = parent[key];
    let child = directorySingleChild(value);
    if (child) {
      let newKey = key;
      while (child) {
        newKey += '/' + child[0];
        value = child[1];
        child = directorySingleChild(value);
      }
      delete parent[key];
      parent[newKey] = value;
    }

    if (typeof value === 'object') {
      flattenDirectories(value);
    }
  };

  for (const key in obj) {
    if (!Object.hasOwn(obj, key)) {
      continue;
    }
    flatten(obj, key);
  }
  return obj;
}

/*
 * Sorts directories first, then by name.
 */
export function directoriesFirstEntries(obj) {
  return Object.entries(obj)
      .sort(([k1, v1], [k2, v2]) => {
        const t1 = typeof v1;
        const t2 = typeof v2;
        return t1 === t2 ? k1.localeCompare(k2) : t1.localeCompare(t2);
      });
}

/*
 * Adds thin spaces around slashes.
 */
export function formatPath(path) {
  return path.replaceAll('/', ' / ');
}
