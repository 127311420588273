import MarkdownCode from './MarkdownCode';
import PropTypes from 'prop-types';
import React from 'react';

const FeatureDocContent = ({ description, usage, options, theme }) => {
  const usageStartsWithHeader = (usage || '').indexOf('## ') === 0;

  return (
    <div className="plugin-info__documentation">
      {description && (
        <div className="plugin-info-section rs-text-2 rs-text-2_hardness_hard">
          <MarkdownCode code={description} theme={theme} />
        </div>
      )}

      {usage && (
        <div className="plugin-info-section rs-text-2 rs-text-2_hardness_hard">
          {!usageStartsWithHeader && (
            <h4 className="rs-h4 rs-h4_flow">Usage</h4>
          )}
          <MarkdownCode code={usage} theme={theme} />
        </div>
      )}

      {options && (
        <div className="plugin-info-section rs-text-2 rs-text-2_hardness_hard">
          <h4 className="rs-h4 rs-h4_flow">Options</h4>
          <MarkdownCode code={options} theme={theme} />
        </div>
      )}
    </div>
  );
};

FeatureDocContent.propTypes = {
  description: PropTypes.string,
  usage: PropTypes.string,
  options: PropTypes.string,
  theme: PropTypes.string,
};

export default FeatureDocContent;
