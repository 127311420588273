import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { GitHubIcon } from '@rescui/icons';

const FeatureLogo = ({ vendor, size }) => (
  vendor.logo ?
    <img
      src={vendor.logo}
      alt={'Logo for ' + vendor.name}
      style={{ width: size }}
    /> :
    <GitHubIcon
      className={cn(vendor.name === 'Ktor' ? '' : 'plugin-item__logo-missing')}
      size="l"
    />
);

FeatureLogo.propTypes = {
  vendor: PropTypes.object,
  size: PropTypes.number,
};

export default FeatureLogo;
