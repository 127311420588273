import './styles/plugins-list.css';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { LoadingIcon, SmileNeutralIcon } from '@rescui/icons';

import FeatureItem from './FeatureItem';
import { groupBy, useViewport } from '../services/helper';
import searchFeatures from '../services/search';
import { trackEvent } from '../constants/analytics';
import ANALYTIC_CATEGORIES from '../constants/analyticCategories';

import { ADD_FEATURE, REMOVE_FEATURE, SHOW_FEATURE_INFO, SHOW_TEMPLATE_INFO } from '../redux/actionTypes';
import { connect } from 'react-redux';
import PluginFilters from './PluginFilters';
import { useTextStyles } from '@rescui/typography';
import cn from 'classnames';

export function denormalizeFeaturesCopyMap(featuresCopyMap) {
  return Object.keys(featuresCopyMap).map((key) => {
    featuresCopyMap[key].requiredFeatures = denormalizeFeatureIdArr(
        featuresCopyMap[key].requiredFeatures,
        featuresCopyMap,
    );
    featuresCopyMap[key].isRequiredForFeatures = denormalizeFeatureIdArr(
        featuresCopyMap[key].isRequiredForFeatures,
        featuresCopyMap,
    );
    return featuresCopyMap[key];
  });
}

export function fullFeaturesWithAddedFlag(features, currentlyAddedIds) {
  return denormalizeFeaturesCopyMap(
      Object.keys(features).reduce((featuresCopyMap, key) => {
        featuresCopyMap[key] = {
          ...features[key],
          id: key,
          wasAdded: (currentlyAddedIds || []).indexOf(key) > -1,
        };
        return featuresCopyMap;
      }, {}),
  );
}

function denormalizeFeatureIdArr(featuresIds, currentFeaturesMap) {
  return featuresIds.map((key) => currentFeaturesMap[key]);
}

function getIds(filteredFeatures) {
  return filteredFeatures
      .reduce((acc, { features }) => acc.concat(features || []), [])
      .map(({ id }) => id);
}

const PluginsList = ({
  visible,
  featuresLoaded,
  searchText,
  vendor,
  category,
  features,
  onFocus,
  addFeature,
  removeFeature,
}) => {
  const featuresByGroup = useMemo(
      () => {
        return Object.entries(groupBy(features, 'group')).map(
            ([name, featuresForGroup]) => ({
              name,
              features: featuresForGroup,
            }),
        );
      },
      [features],
  );

  const [filteredFeatures, setFilteredFeatures] = useState(searchFeatures(searchText.trim(), featuresByGroup));
  const [filteredFeatureIds, setFilteredFeatureIds] = useState(getIds(filteredFeatures));
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const { width } = useViewport();
  const textCn = useTextStyles();

  useEffect(() => {
    const newFilteredFeatures = searchFeatures(searchText.trim(), featuresByGroup, vendor, category);
    setFilteredFeatures(newFilteredFeatures);
    setFilteredFeatureIds(getIds(newFilteredFeatures));
  }, [featuresByGroup, searchText, vendor, category]);

  const selectItem = useCallback((id) => {
    if (id !== selectedItemId) {
      onFocus(id);
      setExpanded(true);
      setSelectedItemId(id);
    } else {
      onFocus(expanded ? null : id);
      setExpanded(!expanded);
      setSelectedItemId(id);
    }
  }, [expanded, filteredFeatureIds, selectedItemId]);

  if (!visible) {
    return (<></>);
  }

  return (
    <>
      <PluginFilters squeeze={width < 590 }/>

      <div className="plugins-list__list">
        {featuresLoaded ? (
          filteredFeatures.length ? (
            filteredFeatures.reduce(
                (acc, { name, features: featuresOfGroup }) =>
                  acc.concat([
                    <div className={cn('plugin-item-separator', textCn('rs-overline'))} key={name}>{name}</div>,
                    ...featuresOfGroup.map((feature) => (
                      <FeatureItem
                        key={`feature-${feature.id + feature.wasAdded}`}
                        feature={feature}
                        isSelected={feature.id === selectedItemId}
                        isExpanded={expanded && feature.id === selectedItemId}
                        selectFeature={selectItem}
                        addFeature={() => {
                          trackEvent(
                              ANALYTIC_CATEGORIES.ACTION,
                              'add-plugin',
                              { name: feature.id },
                          );
                          addFeature(feature.id);
                        }}
                        removeFeature={(args) => {
                          trackEvent(
                              ANALYTIC_CATEGORIES.ACTION,
                              'remove-plugin',
                              { name: feature.id },
                          );
                          removeFeature(args);
                        }}
                      />
                    ),
                    ),
                  ]),
                [],
            )
          ) : (
            <div className="plugins-list__no-content-container">
              <div>
                <SmileNeutralIcon className="rs-text-2 rs-text-3_hardness_pale" />
              </div>
              <div className="rs-text-2 rs-text-3_hardness_pale">
                {'No features found for the current filter '}
              </div>
              <div className="rs-text-2">
                <span
                  className="rs-link rs-link_hardness_average"
                  role="link"
                  tabIndex={0}
                  onClick={() => setSearchValue('')}
                >
                    Reset
                </span>
              </div>
            </div>
          )
        ) : (
          <div className="plugins-list__no-content-container">
            <LoadingIcon theme="light" />
            <div className="rs-text-2 plugins-list__loading">Loading...</div>
          </div>
        )}
      </div>
    </>
  );
};

PluginsList.propTypes = {
  visible: PropTypes.bool,
  searchText: PropTypes.string,
  vendor: PropTypes.string,
  category: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.object).isRequired,
  onFocus: PropTypes.func,
  autoFocus: PropTypes.bool,
  addFeature: PropTypes.func,
  removeFeature: PropTypes.func,
  showRemoveButtons: PropTypes.bool,
  featuresLoaded: PropTypes.bool,
  theme: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  onFocus: (id) => id && dispatch({
    type: id.startsWith('presets__') ? SHOW_TEMPLATE_INFO : SHOW_FEATURE_INFO,
    payload: { id } },
  ),
  addFeature: (id) => dispatch({ type: ADD_FEATURE, payload: { id } }),
  removeFeature: (ids) => dispatch({ type: REMOVE_FEATURE, payload: { ids } }),
});


const mapStateToProps = ({
  features,
  featuresLoaded,
  projectConfig,
  search: { searchText, vendor, category },
}) => {
  const featuresWithRequirements = denormalizeFeaturesCopyMap(
      Object.keys(features).reduce((featuresCopyMap, key) => {
        featuresCopyMap[key] = {
          ...features[key],
          id: key,
          wasAdded: (projectConfig.plugins || []).indexOf(key) > -1,
        };
        return featuresCopyMap;
      }, {}),
  );

  return {
    featuresLoaded,
    features: featuresWithRequirements,
    searchText,
    vendor,
    category,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PluginsList);
