import { Input } from '@rescui/input';
import { SearchIcon } from '@rescui/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { REMOVE_FEATURE, SEARCH } from '../redux/actionTypes';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Select } from '@rescui/select';
import { denormalizeFeaturesCopyMap } from './PluginsList';

const PluginFilters = ({
  allPlugins,
  search,
  theme,
}) => {
  const { vendors, categories } = useMemo(() => {
    const vendors = {};
    const categories = {};
    Object.values(allPlugins).forEach((plugin, i) => {
      vendors[plugin.vendor?.name] = (vendors[plugin.vendor?.name] || 0) + 1;
      categories[plugin.group] = (categories[plugin.group] || 0) + 1;
    });
    const vendorOptions = Object.entries(vendors)
        .sort((a, b) => b[1] - a[1])
        .map(([vendor, count]) => ({
          label: <div className="plugin-dropdown-option">{vendor} <span>{count}</span></div>,
          value: vendor,
        }));
    const categoryOptions = Object.entries(categories).sort()
        .map(([category, count]) => ({
          label: <div className="plugin-dropdown-option">{category} <span>{count}</span></div>,
          value: category,
        }));
    return {
      vendors: vendorOptions,
      categories: categoryOptions,
    };
  }, [allPlugins]);

  const [searchText, setSearchText] = useState('');
  const onChangeSearchValue = (e) =>
    setSearchText(
        (e.target.value || '').replace(/^ +/, '').replace(/ +$/, ' '),
    );
  const [vendor, setVendor] = useState(null);
  const [category, setCategory] = useState(null);

  useEffect(() => {
    search({
      searchText,
      vendor: vendor?.value,
      category: category?.value,
    });
  }, [searchText, vendor, category]);

  return (
    <div className="control-panel__plugin-filters">
      <Input
        className="control-panel__plugin-search"
        theme={theme}
        value={searchText}
        onChange={onChangeSearchValue}
        onClear={() => setSearchText('')}
        placeholder="Search plugins"
        icon={<SearchIcon />}
      />
      <Select
        theme={theme}
        className="content-panel__plugin-select"
        options={vendors}
        value={vendor}
        placeholder="Select provider..."
        onChange={setVendor}
        isClearable={true}
      />
      <Select
        theme={theme}
        className="content-panel__plugin-select"
        options={categories}
        value={category}
        placeholder="Select category..."
        onChange={setCategory}
        isClearable={true}
      />
    </div>
  );
};

PluginFilters.propTypes = {
  allPlugins: PropTypes.object,
  selectedPlugins: PropTypes.array,
  search: PropTypes.func,
  removePlugin: PropTypes.func,
  squeeze: PropTypes.bool,
  theme: PropTypes.string,
};


const mapDispatchToProps = (dispatch) => ({
  search: ({ searchText, vendor, category }) => dispatch({ type: SEARCH, payload: { searchText, vendor, category } }),
  removePlugin: (ids) => dispatch({ type: REMOVE_FEATURE, payload: { ids } }),
});

const mapStateToProps = ({
  features,
  settingsLoaded,
  projectConfig,
  generatedProject,
  theme,
}) => {
  // TODO copy pasta
  const featuresWithRequirements = denormalizeFeaturesCopyMap(
      Object.keys(features).reduce((featuresCopyMap, key) => {
        featuresCopyMap[key] = {
          ...features[key],
          id: key,
          wasAdded: (projectConfig.plugins || []).indexOf(key) > -1,
        };
        return featuresCopyMap;
      }, {}),
  );

  return {
    allPlugins: features,
    selectedPlugins: featuresWithRequirements.filter((f) => f.wasAdded),
    settingsLoaded,
    projectConfig,
    generatedProject,
    theme,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PluginFilters);
