import { createStore, applyMiddleware } from 'redux';
import { throttle } from 'throttle-debounce';
import rootReducer from './reducers';
import { loadState, saveState } from '../services/storage';
import fetchFeatureList from './middleware/fetchFeatureList';
import fetchInitializationData from './middleware/fetchInitializationData';
import generateProject from './middleware/generateProject';
import fetchFeatureInfo from './middleware/fetchFeatureInfo';
import fetchFeatureDescriptor from './middleware/fetchFeatureDescriptor';
import trackAnalyticsEvents from './middleware/trackAnalyticsEvents';
import syncProjectConfigWithQueryString from './middleware/syncProjectConfigWithQueryString';
import editPluginsList from './middleware/editPluginsList';
import generateProjectPreview from './middleware/previewProject';

const store = createStore(
    wrappedReducer,
    loadState(),
    applyMiddleware(
        fetchInitializationData,
        fetchFeatureList,
        generateProjectPreview,
        generateProject,
        editPluginsList,
        fetchFeatureInfo,
        fetchFeatureDescriptor,
        trackAnalyticsEvents,
        syncProjectConfigWithQueryString,
    ),
);
const save = throttle(500, () => saveState(store.getState()));
store.subscribe(save);

function wrappedReducer(state, action) {
  const result = rootReducer(state, action);
  window.state = result;
  return result;
}

export default store;
