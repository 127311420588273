export const templateModels = [{
  id: 'presets__rest',
  name: 'REST Service',
  added: false,
  options: {
    'Authentication': { plugins: ['auth-jwt', 'auth-basic', 'auth-ldap', 'auth-oauth'] },
    'Serialization': { mutuallyExclusive: true, plugins: ['kotlinx-serialization', 'ktor-gson', 'ktor-jackson'] },
    'APIs': { plugins: ['openapi', 'swagger'] },
  },
}, {
  id: 'presets__website',
  name: 'Templated Website',
  added: false,
  options: {
    'Templating': { mutuallyExclusive: true, plugins: ['html-dsl', 'freemarker', 'mustache', 'pebble', 'thymeleaf', 'velocity'] },
    'Static Content': { plugins: ['static-content'] },
    'Status Pages': { plugins: ['status-pages'] },
  },
}, {
  id: 'presets__microservice',
  name: 'Distributed Microservice',
  added: false,
  options: {
    'Serialization': { mutuallyExclusive: true, plugins: ['kotlinx-serialization', 'ktor-gson', 'ktor-jackson'] },
    'Events': { plugins: ['ktor-server-kafka-jvm'] },
  },
}];
