import './styles/features-info.css';

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LoadingIcon } from '@rescui/icons';
import FeatureDocContent from './FeatureDocContent';
import VcsLink from './VcsLink';
import VendorLink from './VendorLink';
import PluginLinkButton from './PluginLinkButton';

const FeatureMetaInfo = ({ feature }) => {
  return (
    <div className="plugin-info__meta">
      <VcsLink link={ feature.vcsLink } size="s" />
      <VendorLink vendor={ feature.vendor || {} } size="s" />
      <div className="flex-gap"></div>
      <PluginLinkButton plugin={feature} size="s" />
    </div>
  );
};

FeatureMetaInfo.propTypes = {
  feature: PropTypes.any,
};

const FeatureInfo = ({
  status,
  feature,
  description,
  usage,
  options,
  theme,
}) => {
  if (status === 'not-selected' || !feature) {
    return '';
  }

  return (
    <>
      <FeatureMetaInfo feature={feature} />
      {status === 'has-info' && (
        <FeatureDocContent
          description={description}
          usage={usage}
          options={options}
          theme={theme}
        />
      )}
      {status === 'no-info' && (
        <LoadingIcon className="rs-offset-top-12" theme="light" />
      )}
    </>
  );
};

FeatureInfo.propTypes = {
  feature: PropTypes.any,
  status: PropTypes.string,
  description: PropTypes.string,
  usage: PropTypes.string,
  options: PropTypes.string,
  theme: PropTypes.string,
};

const mapStateToProps = ({ selectedFeatureId, docs, theme }) => {
  const getStatus = () => {
    if (!selectedFeatureId) {
      return 'not-selected';
    }
    if (!docs[selectedFeatureId]) {
      return 'no-info';
    }

    return 'has-info';
  };

  return {
    status: getStatus(),
    id: selectedFeatureId,
    theme,
    ...(docs[selectedFeatureId] || {}).content,
  };
};

export default connect(mapStateToProps)(FeatureInfo);
