import {
  SHOW_ALERT,
  LOAD_FEATURE_DESCRIPTOR,
  SHOW_FEATURE_DESCRIPTOR,
} from '../actionTypes';
import { trackError } from '../../constants/analytics';
import fetchApi from '../../services/fetchApi';
import { makeErrorAlert } from '../../services/helper';

export default function fetchFeatureInfo({ dispatch, getState }) {
  const loadDescriptor = (data) =>
    dispatch({ type: LOAD_FEATURE_DESCRIPTOR, payload: data });

  const onError = (errorMessage) =>
    dispatch({
      type: SHOW_ALERT,
      payload: { alert: makeErrorAlert(errorMessage) },
    });

  return (next) => (action) => {
    if (
      action.type === SHOW_FEATURE_DESCRIPTOR &&
      action.payload &&
      action.payload.id
    ) {
      const { descriptors } = getState();

      const featureId = action.payload.id;
      const infoLoaded = descriptors[featureId];

      if (!infoLoaded) {
        fetchApi(`/descriptors/latest/${featureId}`)
            .then((content) =>
              loadDescriptor({
                data: content,
                id: featureId,
              }),
            )
            .catch(({ message, status }) => {
              trackError(`load-feature-doc-${status}`);
              return onError(message || 'Something went wrong');
            });
      }
    }

    return next(action);
  };
}
