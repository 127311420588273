export const INITIALIZE = 'INITIALIZE';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const LOAD_SETTINGS = 'LOAD_SETTINGS';
export const LOAD_FEATURES = 'LOAD_FEATURES';
export const LOAD_FEATURE_INFO = 'LOAD_FEATURE_INFO';
export const SHOW_FEATURE_INFO = 'SHOW_FEATURE_INFO';
export const SHOW_FEATURE_DESCRIPTOR = 'SHOW_FEATURE_DESCRIPTOR';
export const LOAD_FEATURE_DESCRIPTOR = 'LOAD_FEATURE_DESCRIPTOR';
export const UPDATE_SERIALIZATION_MODEL = 'UPDATE_SERIALIZATION_MODEL';
export const ADD_FEATURE = 'ADD_FEATURE';
export const REMOVE_FEATURE = 'REMOVE_FEATURE';
export const SWITCH_PARAMETERS_MODE = 'SWITCH_PARAMETERS_MODE';
export const CHANGE_PARAMETER_VALUE = 'CHANGE_PARAMETER_VALUE';
export const START_LOAD_FEATURES = 'START_LOAD_FEATURES';
export const START_PROJECT_PREVIEW = 'START_PROJECT_PREVIEW';
export const FINISH_PROJECT_PREVIEW = 'FINISH_PROJECT_PREVIEW';
export const FAILED_PROJECT_PREVIEW = 'FAILED_PROJECT_PREVIEW';
export const START_PROJECT_GENERATING = 'START_PROJECT_GENERATING';
export const FINISH_PROJECT_GENERATING = 'FINISH_PROJECT_GENERATING';
export const FAILED_PROJECT_GENERATING = 'FAILED_PROJECT_GENERATING';
export const HIDE_ALERT = 'HIDE_ALERT';
export const SHOW_ALERT = 'SHOW_ALERT';
export const SEARCH = 'SEARCH';
export const TOGGLE_THEME = 'TOGGLE_THEME';
export const ADD_TEMPLATE = 'ADD_TEMPLATE';
export const REMOVE_TEMPLATE = 'REMOVE_TEMPLATE';
export const SHOW_TEMPLATE_INFO = 'SHOW_TEMPLATE_INFO';
