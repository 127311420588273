import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import copyToClipboard from 'copy-to-clipboard';
import { connect } from 'react-redux';
import Icon, { Size } from '@jetbrains/ring-ui-built/components/icon';
import CopyIcon from '@jetbrains/icons/copy';


import { makeAlert, trim } from '../services/helper';
import ANALYTIC_CATEGORIES from '../constants/analyticCategories';
import { SHOW_ALERT } from '../redux/actionTypes';
import { trackEvent } from '../constants/analytics';

import './styles/final-step.css';
import WelcomeTutorial from './WelcomeTutorial';
import { useCardCn } from '@rescui/card';
import cn from 'classnames';
import { useTextStyles } from '@rescui/typography';

const FinalStep = ({
  generatedProject,
  templateLink,
  onCopySuccess,
  theme,
}) => {
  const downloadUrl = useMemo(
      () => window.URL.createObjectURL(generatedProject.blob || new Blob()),
      [generatedProject],
  );

  const onLinkClick = useCallback(
      (linkKey) => trackEvent(ANALYTIC_CATEGORIES.LINK, linkKey),
      [],
  );

  const navigateToDocumentation = useCallback(
      () => onLinkClick('navigate-to-documentation'),
      [onLinkClick],
  );

  const copyLinkToProjectTemplate = useCallback(
      (evt) => {
        evt.preventDefault();
        copyToClipboard(templateLink);
        onCopySuccess('Link to current project template is copied!');
        return onLinkClick('copy-link-to-template');
      },
      [onLinkClick, templateLink],
  );

  const downloadByLink = useCallback(
      () => onLinkClick('download-by-direct-link'),
      [onLinkClick],
  );

  const textCn = useTextStyles();
  const cardCn = useCardCn();

  return (
    <div className="final-step">
      <section className={cn(cardCn(), 'final-step__download-blurb')}>
        <h2 className={textCn('rs-h2')}>Your Project is Ready to Go!</h2>
        <div>
          <p className={textCn('rs-text-2', { hardness: 'hard' })}>
            {'Project '}
            <strong>{trim(generatedProject.fileName)}</strong>
            {' download should start shortly.'}
            <br />
            {'If it does not, please, use the '}
            <a
              className="rs-link"
              href={downloadUrl}
              onClick={downloadByLink}
              download={generatedProject.fileName}
            >
              direct link
            </a>
            {'.'}
          </p>
        </div>

        <div>
          <p className="rs-text-2 rs-text-2_hardness_hard final-step__toolbar">
            <span className="final-step__toolbar-item">
              <a
                href={templateLink}
                className="rs-link"
                onClick={copyLinkToProjectTemplate}
              >
                {'Copy Link to Template'}
              </a>
              <Icon
                role="button"
                className="final-step__icon"
                glyph={CopyIcon}
                color={Icon.Color.GRAY}
                size={Size.Size14}
                onClick={copyLinkToProjectTemplate}
              />
            </span>

            <span className="final-step__toolbar-item">
              <a
                href="https://ktor.io/docs/welcome.html"
                className="rs-link"
                target="_blank"
                onClick={navigateToDocumentation}
                rel="noreferrer"
              >
                {'View Docs and Tutorials'}
              </a>
            </span>
          </p>
        </div>
      </section>

      <WelcomeTutorial theme={theme} />
    </div>
  );
};

FinalStep.propTypes = {
  generatedProject: PropTypes.object,
  templateLink: PropTypes.string,
  onCopySuccess: PropTypes.func,
  theme: PropTypes.string,
};

const mapStateToProps = ({
  projectConfig,
  generatedProject,
  serializationModel,
  theme,
}) => ({
  templateLink: `${window.location.protocol}//${
    window.location.host
  }settings?${serializationModel.toSearchString(projectConfig)}`,
  generatedProject,
  theme,
});

const mapDispatchToProps = (dispatch) => ({
  onCopySuccess: (message) =>
    dispatch({
      type: SHOW_ALERT,
      payload: { alert: makeAlert(message) },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinalStep);
