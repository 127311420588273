import './styles/project-form.css';

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { CHANGE_PARAMETER_VALUE } from '../redux/actionTypes';
import { connect } from 'react-redux';
import ArtifactInput from './ArtifactInput';
import KtorOptions from './KtorOptions';

const ProjectForm = ({
  projectArtifact,
  changeParameter,
  flatten,
}) => {
  const changeTextValueCallback = (param) => useCallback((value) => changeParameter(param, value), [changeParameter]);

  return (
    <div className="project-artifact-form">
      <ArtifactInput
        projectArtifact={projectArtifact}
        setProjectArtifact={changeTextValueCallback('artifact')}
        setProjectName={changeTextValueCallback('name')}
      />

      <KtorOptions flatten={flatten} />

    </div>
  );
};

ProjectForm.propTypes = {
  projectArtifact: PropTypes.string,
  changeParameter: PropTypes.func.isRequired,
  flatten: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  changeParameter: (name, value) =>
    dispatch({
      type: CHANGE_PARAMETER_VALUE,
      payload: { name, value },
    }),
});

const mapStateToProps = ({ projectConfig }) => ({
  projectArtifact: projectConfig.artifact,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectForm);
