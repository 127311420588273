
// eslint-disable-next-line no-unused-vars
import Alert, { AlertType } from '@jetbrains/ring-ui-built/components/alert/alert';
import React from 'react';

export function makeErrorAlert(message) {
  return makeAlert(message, Alert.Type.ERROR);
}

/**
 * Generates an alert object with a unique key, type, message, and other properties.
 *
 * @param {string} message - The message to display in the alert.
 * @param {AlertType} [type=AlertType] - The type of alert, default is 'SUCCESS'.
 * @return {Object} An alert object with a unique key, type, message, isShaking status, and timeout duration.
 */
export function makeAlert(message, type = Alert.Type.SUCCESS) {
  return {
    key: `alert-${Date.now()}`,
    type,
    message,
    isShaking: true,
    timeout: 5000,
  };
}

export function groupBy(xs, key) {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function getAllTransitiveParents(
    feature,
    accumulator = null,
    featuresMap = {},
) {
  return distinctById(
      (featuresMap[feature] || feature).isRequiredForFeatures.reduce(
          (result, parent) => {
            return result.concat([
              parent,
              ...getAllTransitiveParents(parent, result, featuresMap),
            ]);
          },
          accumulator || [],
      ),
  );
}

export function getAllTransitiveChildren(
    feature,
    accumulator = null,
    featuresMap = {},
) {
  const current = featuresMap[feature] || feature;
  if (!current || !current.requiredFeatures) {
    console.log('current', current);
    return [];
  }
  return distinctById(
      current.requiredFeatures.reduce(
          (result, parent) =>
            result.concat([
              parent,
              ...getAllTransitiveChildren(parent, result, featuresMap),
            ]),
          accumulator || [],
      ),
  );
}

export function distinctById(featuresArr) {
  return Array.from(
      new Set(featuresArr
          .filter((feature) => !!feature)
          .map((feature) => feature.id || feature),
      ),
  ).map(
      (featureId) =>
        featuresArr.filter((feature) => (feature.id || feature) === featureId)[0],
  );
}

export function trim(str, maxLength = 40) {
  return str.length > maxLength ? `${str.substr(0, 20)}...` : str;
}

export function makeArtifactName(website, projectName) {
  const normalizedProjectName = (projectName || '')
      .toLowerCase()
      .replace(/[^\w]+/g, '-')
      .replace(/-$/g, '')
      .replace(/^-/g, '');
  const lastToken = normalizedProjectName || 'app';
  return `${(website || '').split('.').reverse().join('.')}.${lastToken}`;
}

export function linkToPath(newPath) {
  return window.location.protocol + '//' + window.location.hostname +
    (window.location.port ? ':' + window.location.port: '') + newPath;
}

export function useViewport() {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return { width };
}

export function useScroll(elementId) {
  const [scrollY, setScrollY] = React.useState(0);
  let currentY = 0;

  React.useEffect(() => {
    const element = elementId && document.getElementById(elementId) || window;
    // requestAnimationFrame will prevent jitter from too many scroll events firing
    const handleScroll = (e) => {
      currentY = e.target.scrollTop;
      requestAnimationFrame(updateScroll);
    };
    const updateScroll = () => {
      setScrollY(currentY);
    };
    element.addEventListener('scroll', handleScroll);
    return () => element.removeEventListener('scroll', handleScroll);
  }, []);

  return { scrollY: scrollY };
}

export function filterByPrefix(obj, prefix) {
  const result = {};
  for (const key in obj) {
    if (key.startsWith(prefix)) {
      result[key] = obj[key];
    }
  }
  return result;
}
