import React from 'react';
import { MenuItem } from '@rescui/menu';
import PropTypes from 'prop-types';
import { FileIcon, FolderIcon, KotlinIcon } from '@rescui/icons';
import { directoriesFirstEntries, formatPath } from '../services/directories';
import { fileContentsSkipped } from './ProjectPreview';

const getIconForFile = (name) => {
  const ext = name.substring(name.indexOf('.') + 1);
  switch (ext) {
    case 'kt': return (<KotlinIcon size="s" />);
    default: return (<FileIcon size="s" />);
  }
};

const FileNavItem = ({ path, name, value, selectedFiles, onSelect }) => {
  return typeof value === 'string' ? (
      <MenuItem
        icon={getIconForFile(name)}
        disabled={fileContentsSkipped(value)}
        selected={selectedFiles.hasOwnProperty(path)}
        onClick={() => onSelect({ [path]: value })}
      >
        {name}
      </MenuItem>
    ) : (
      <FolderNavItem
        path={path}
        name={name}
        value={value}
        selectedFiles={selectedFiles}
        onSelect={onSelect}
      />
    );
};

const FolderNavItem = ({ path, name, value, selectedFiles, onSelect }) => {
  return (
    <>
      <MenuItem
        icon={<FolderIcon size="s" />}
        onClick={() => {
          const entries = directoriesFirstEntries(value);
          const result = Object.fromEntries(entries.map(([file, contents]) => [path + '/' + file, contents]));
          onSelect(result);
        }}
      >
        { formatPath(name) }
      </MenuItem>
      {
        typeof value === 'object' &&
          <div className="preview-project-panel__folder-nav">
            {
              directoriesFirstEntries(value).map(([child, contents]) => (
                <FileNavItem
                  key={path + '/' + child}
                  path={path + '/' + child}
                  name={child}
                  value={contents}
                  selectedFiles={selectedFiles}
                  onSelect={onSelect}
                />
              ))
            }
          </div>
      }
    </>
  );
};

const fileNavItemPropTypes = {
  path: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  selectedFiles: PropTypes.object,
  onSelect: PropTypes.func,
};

FileNavItem.propTypes = fileNavItemPropTypes;
FolderNavItem.propTypes = fileNavItemPropTypes;

export default FileNavItem;
