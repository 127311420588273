import React from 'react';
import { Provider } from 'react-redux';
import Application from './app/Application';
import store from './redux/store';

import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PluginPreview from './app/PluginPreview';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Provider store={store}>
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/*" element={<Application />} />
          <Route path="/p/*" element={<PluginPreview />} />
        </Routes>
      </BrowserRouter>
    </Provider>,
);
