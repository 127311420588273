import {
  ADD_FEATURE,
  CHANGE_PARAMETER_VALUE,
  REMOVE_FEATURE,
} from '../actionTypes';
import { distinctById, getAllTransitiveChildren } from '../../services/helper';

export default function editPluginsList({ getState, dispatch }) {
  const changePluginsInProjectConfig = (newAddedPlugins) =>
    dispatch({
      type: CHANGE_PARAMETER_VALUE,
      payload: { name: 'plugins', value: newAddedPlugins },
    });

  const getUpdatedAddedPluginsList = (action, state) => {
    const { projectConfig, features: allPlugins } = state;
    const addedPlugins = projectConfig.plugins || [];

    if (action.type === ADD_FEATURE) {
      return distinctById(
          getAllTransitiveChildren(action.payload.id, [], allPlugins)
              .concat([action.payload.id])
              .concat(addedPlugins),
      );
    }
    if (action.type === REMOVE_FEATURE) {
      return addedPlugins.filter(
          (addedPluginId) => !action.payload.ids.some((id) => addedPluginId === id),
      );
    }

    return null;
  };

  return (next) => (action) => {
    const newPluginsList = getUpdatedAddedPluginsList(action, getState());
    if (newPluginsList) {
      changePluginsInProjectConfig(newPluginsList);
    }

    return next(action);
  };
}
