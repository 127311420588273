import React, { useEffect } from 'react';

import './styles/markdown-style.css';
import PropTypes from 'prop-types';
import MarkdownCode from './MarkdownCode';


function WelcomeTutorial({ theme }) {
  const [tutorial, setTutorial] = React.useState('');
  // fetch welcome tutorial
  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/GettingStartedWithKtorV4.md`)
        .then((response) => response.text())
        .then((text) => setTutorial(text))
        .catch(() => undefined);
  }, []);

  return (
    <section className="tutorial">
      <MarkdownCode code={tutorial} theme={theme} />
    </section>
  );
}

WelcomeTutorial.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default WelcomeTutorial;
