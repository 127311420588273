import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button } from '@rescui/button';
import { DownIcon } from '@rescui/icons';
import Popup from '@jetbrains/ring-ui-built/components/popup/popup';
import Dropdown from '@jetbrains/ring-ui-built/components/dropdown/dropdown';


import RemoveFeatureConfirmation from './RemoveFeatureConfirmation';
import {
  getAllTransitiveParents,
  getAllTransitiveChildren,
} from '../services/helper';

const FeatureItemAction = ({
  feature,
  removeFeature,
  addFeature,
  isAlwaysShown,
  size,
}) => {
  const [showActionButtonDropdown, setShowActionButtonDropdown] =
    useState(false);

  const toggleActionDropdown = useCallback(() => {
    setShowActionButtonDropdown(!showActionButtonDropdown);
  }, [showActionButtonDropdown]);

  const doAddFeature = useCallback(
      (e) => {
        addFeature();
        e.stopPropagation();
      },
      [addFeature],
  );

  const actionButtonDefaultProps = useMemo(
      () => ({
        className: cn('plugin-item__button', {
          'plugin-item__button_shown': showActionButtonDropdown || isAlwaysShown,
        }),
        theme: 'dark',
        size: size || 'm',
      }),
      [showActionButtonDropdown, isAlwaysShown],
  );

  const addedParents = useMemo(() => {
    return feature.wasAdded ?
      getAllTransitiveParents(feature).filter((parent) => parent.wasAdded) :
      [];
  }, [feature]);

  const unusedDependencies = useMemo(
      () =>
      feature.wasAdded ?
        getAllTransitiveChildren(feature).filter(
            (child) =>
              (child.isRequiredForFeatures || []).filter(
                  (parentFeature) =>
                    parentFeature.wasAdded &&
                  !addedParents.some(
                      (addedParent) => addedParent.id === parentFeature.id,
                  ),
              ).length < 2,
        ) :
        [],
      [feature, addedParents],
  );

  if (!feature.wasAdded) {
    return (
      <Button {...actionButtonDefaultProps} onClick={doAddFeature}>
        Add
      </Button>
    );
  }

  if (!unusedDependencies.length && !addedParents.length) {
    return (
      <Button
        {...actionButtonDefaultProps}
        onClick={() => removeFeature([feature.id])}
      >
        Remove
      </Button>
    );
  }

  return (
    <Dropdown
      onShow={() => setShowActionButtonDropdown(true)}
      onHide={() => setShowActionButtonDropdown(false)}
      anchor={() => (
        <Button
          icon={<DownIcon />}
          {...actionButtonDefaultProps}
          onClick={toggleActionDropdown}
          iconPosition="right"
        >
          Remove
        </Button>
      )}
    >
      <Popup>
        <RemoveFeatureConfirmation
          feature={feature}
          addedParents={addedParents}
          unusedDependencies={unusedDependencies}
          removeFeature={removeFeature}
        />
      </Popup>
    </Dropdown>
  );
};

FeatureItemAction.propTypes = {
  feature: PropTypes.object,
  isAlwaysShown: PropTypes.bool,
  addFeature: PropTypes.func,
  removeFeature: PropTypes.func,
  size: PropTypes.string,
};

export default FeatureItemAction;
