import './styles/plugin-item.css';

import React from 'react';
import PropTypes from 'prop-types';
import FeatureLogo from './FeatureLogo';
import FeatureInfo from './FeatureInfo';
import PluginListItem from './PluginListItem';
import FeatureItemAction from './FeatureItemAction';

const FeatureItem = React.forwardRef(function FeatureItem(
    {
      feature,
      isSelected,
      isExpanded,
      selectFeature,
      addFeature,
      removeFeature,
    },
    ref,
) {
  return <PluginListItem
    ref={ref}
    id={feature.id}
    icon={<FeatureLogo vendor={feature.vendor} size={32} />}
    name={feature.name}
    description={<span>{feature.description}&nbsp;&middot;&nbsp;{feature.vendor.name}</span>}
    trigger={<FeatureItemAction
      feature={feature}
      removeFeature={removeFeature}
      addFeature={addFeature}
      isSelected={isSelected}
    />}
    isAdded={feature.wasAdded}
    isExpanded={isExpanded}
    isSelected={isSelected}
    onSelect={selectFeature}
    expandedContent={<FeatureInfo feature={feature} />}
  />;
});

FeatureItem.propTypes = {
  feature: PropTypes.object,
  isSelected: PropTypes.bool,
  isExpanded: PropTypes.bool,
  showControls: PropTypes.bool,
  selectFeature: PropTypes.func,
  addFeature: PropTypes.func,
  removeFeature: PropTypes.func,
};

export default FeatureItem;
