import { trackEvent } from '../../constants/analytics';
import ANALYTIC_CATEGORIES from '../../constants/analyticCategories';
import {
  CHANGE_PARAMETER_VALUE,
  INITIALIZE,
  SHOW_FEATURE_INFO,
} from '../actionTypes';

export default function trackAnalyticsEvents() {
  const trackedDetailedParametersChanges = {};

  const trackReduxActionsToAnalytics = (action) => {
    if (
      action.type === CHANGE_PARAMETER_VALUE &&
      action.payload?.name &&
      action.payload.name !== 'name' &&
      !trackedDetailedParametersChanges[action.payload.name]
    ) {
      trackEvent(
          ANALYTIC_CATEGORIES.ACTION,
          'change-detailed-parameter',
          {
            name: action.payload?.name,
          },
      );
      trackedDetailedParametersChanges[action.payload.name] = true;
    } else if (action.type === SHOW_FEATURE_INFO && action.payload?.id) {
      trackEvent(ANALYTIC_CATEGORIES.ACTION, 'view-plugin-info', {
        plugin: action.payload?.id,
      });
    }
  };

  return (next) => (action) => {
    if (action.type !== INITIALIZE) {
      trackReduxActionsToAnalytics(action);
    }

    return next(action);
  };
}
