import React, { useMemo } from 'react';
import { GitHubIcon } from '@rescui/icons';
import { Button } from '@rescui/button';
import PropTypes from 'prop-types';

// TODO handle other VCS locations (gitlab, bitbucket, space, etc.)
const GIT_URL = 'https://github.com/';

const VcsLink = ({ link, size }) => {
  const vcsText = useMemo(
      () => {
        let path = link || '';
        if (path.includes(GIT_URL)) {
          path = path.replace(GIT_URL, '');
          const [org, repo] = path.split('/');
          if (org && repo) {
            path = `${org} / ${repo}`;
          }
        }
        return path;
      },
      [link],
  );

  return (
    <Button
      icon={<GitHubIcon />}
      mode="clear"
      size={ size || 'xs' }
      href={ link }
      target="_blank"
      rel="noreferrer"
    >
      { vcsText }
    </Button>
  );
};

VcsLink.propTypes = {
  link: PropTypes.string,
  size: PropTypes.string,
};

export default VcsLink;
