import './styles/reset.css';
import './styles/ktor-generator-app.css';
import '@rescui/colors/lib/index.css';
import '@rescui/typography/lib/index.css';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import { Navigate, Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import FocusManager from '@rescui/focus-manager';

import { trackEvent } from '../constants/analytics';
import Steps from '../constants/steps';

import { INITIALIZE, TOGGLE_THEME, UPDATE_LOCATION } from '../redux/actionTypes';
import AlertContainer from './AlertContainer';
import FinalStep from './FinalStep';
import ANALYTIC_CATEGORIES from '../constants/analyticCategories';
import { ThemeProvider } from '@rescui/ui-contexts';
import KtorPageHeader from './KtorPageHeader';
import ControlPanel from './ControlPanel';
import cn from 'classnames';
import PluginsList from './PluginsList';
import { Tab, TabList, TabSeparator } from '@rescui/tab-list';
import ProjectPreview from './ProjectPreview';
import { ProjectsIcon, Team2Icon } from '@rescui/icons';
import SelectedPlugins from './SelectedPlugins';

const Application = ({
  settingsLoaded,
  configureParams,
  loadSettings,
  generatedProject,
  toggleTheme,
  theme,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, search } = location;
  const [, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    configureParams(
        QueryString.parse(search),
        (params) => setSearchParams(params, { replace: true }),
    );
  }, [pathname]);

  useEffect(() => {
    if (!settingsLoaded) {
      loadSettings(QueryString.parse(search));
    }
    // eslint-disable-next-line no-new
    new FocusManager();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div id="kt-gen1" className={cn('ktor-project-generator', theme)}>
        <KtorPageHeader toggleTheme={toggleTheme} />
        <Routes>
          <Route path="settings" element={
            <main>
              <ControlPanel
                onGenerate={()=> {
                  trackEvent(
                      ANALYTIC_CATEGORIES.MOVE_TO_STEP,
                      [location.pathname.split('/').pop(), Steps.FINAL].join('-to-'),
                  );
                  navigate({ pathname: '/final', search });
                }}>
                <div className="control-panel__tabs">
                  <TabList value={activeTab} onChange={ (i) => setActiveTab(i) }>
                    <Tab icon={<ProjectsIcon />}>Plugins</Tab>
                    <Tab icon={<Team2Icon />}>Preview</Tab>
                  </TabList>
                  <SelectedPlugins />
                </div>
                <TabSeparator />
              </ControlPanel>
              <PluginsList visible={activeTab === 0} />
              <ProjectPreview visible={activeTab === 1} />
            </main>
          }/>
          <Route path="final" element={
            <main>
              {
                generatedProject?.blob ?
                  (<FinalStep generatedProject={generatedProject} />) :
                  (<Navigate to="/settings" replace />)
              }
            </main>
          }
          />
          <Route path="*" element={
            <Navigate to="/settings" replace />}
          />
        </Routes>

        <AlertContainer />

      </div>
    </ThemeProvider>
  );
};

Application.propTypes = {
  settingsLoaded: PropTypes.bool.isRequired,
  loadSettings: PropTypes.func.isRequired,
  configureParams: PropTypes.func.isRequired,
  generatedProject: PropTypes.object.isRequired,
  toggleTheme: PropTypes.func,
  theme: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  loadSettings: (queryParams) => {
    dispatch({ type: INITIALIZE, payload: { queryParams } });
  },
  configureParams: (queryParams, setParams) => {
    dispatch({ type: UPDATE_LOCATION, payload: { queryParams, setParams } });
  },
  toggleTheme: () => {
    dispatch({ type: TOGGLE_THEME });
  },
});

const mapStateToProps = ({ settingsLoaded, projectConfig, generatedProject, theme }) => ({
  settingsLoaded,
  projectConfig,
  generatedProject,
  theme,
});

export default connect(mapStateToProps, mapDispatchToProps)(Application);
