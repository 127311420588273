export function trackEvent(category, action, customProps) {
  push({
    eventCategory: category,
    eventAction: action,
    ...customProps,
  });
}

export function trackError(errorCode, fatal = false) {
  push( {
    eventCategory: 'exception',
    description: errorCode,
    fatal,
  });
}

function push(args) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAEvent',
    ...args,
  });
}
