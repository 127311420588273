import './styles/remove-feature-confirmation.css';

import React, { useCallback, useMemo, useState } from 'react';
import { Button } from '@rescui/button';
import { CheckboxControl as Checkbox } from '@rescui/checkbox';
import PropTypes from 'prop-types';

import {
  getAllTransitiveParents,
  distinctById,
  getAllTransitiveChildren,
} from '../services/helper';

function getPluginsStringPluralForm(arr) {
  return arr.length > 1 ? 'plugins' : 'plugin';
}

const RemoveFeatureConfirmation = ({
  feature,
  addedParents,
  unusedDependencies,
  removeFeature,
}) => {
  const [excludedFromRemovalFeatures, setExcludedFromRemovalFeatures] =
    useState([]);

  const doRemoveFeature = useCallback(() => {
    const featuresIdsToRemove = [feature.id].concat(
        [
          ...addedParents,
          ...unusedDependencies.filter(
              (dependency) =>
                !excludedFromRemovalFeatures.some(
                    (excludedDependency) => excludedDependency.id === dependency.id,
                ),
          ),
        ].map((linkedFeature) => linkedFeature.id),
    );
    removeFeature(featuresIdsToRemove);
  }, [
    feature,
    addedParents,
    unusedDependencies,
    removeFeature,
    excludedFromRemovalFeatures,
  ]);

  const featuresNumberToRemove = useMemo(
      () =>
        addedParents.length +
      unusedDependencies.length -
      excludedFromRemovalFeatures.length +
      1,
      [addedParents, unusedDependencies, excludedFromRemovalFeatures],
  );

  const toggleDependencyCheckbox = (linkedFeature, shouldBeRemoved) => {
    const featuresToToggle = distinctById(
        getAllTransitiveChildren(linkedFeature)
            .concat(shouldBeRemoved ? getAllTransitiveParents(linkedFeature) : [])
            .concat([linkedFeature]),
    ).filter((parentFeature) =>
      unusedDependencies.some(
          (unusedDependency) => unusedDependency.id === parentFeature.id,
      ),
    );
    const newExcludedArr = shouldBeRemoved ?
      excludedFromRemovalFeatures.filter(
          (excludedFeature) =>
            !featuresToToggle.some(
                (featureToToggle) => excludedFeature.id === featureToToggle.id,
            ),
      ) :
      distinctById(excludedFromRemovalFeatures.concat(featuresToToggle));
    setExcludedFromRemovalFeatures(newExcludedArr);
  };

  return (
    <div className="remove-feature-confirmation">
      <div className="remove-feature-confirmation__content">
        {addedParents.length > 0 && (
          <div className="remove-feature-confirmation__section">
            <strong>
              {`Removing "${
                feature.name
              }" will remove the following parent ${getPluginsStringPluralForm(
                  addedParents,
              )}:`}
            </strong>
            {addedParents.map((parent) => (
              <div
                key={parent.id}
                className="remove-feature-confirmation__list-item"
              >
                <label className="remove-feature-confirmation__label">
                  <Checkbox defaultChecked disabled />
                  <div className="remove-feature-confirmation__label-text">
                    {parent.name}
                  </div>
                </label>
              </div>
            ))}
          </div>
        )}

        {unusedDependencies.length > 0 && (
          <div className="remove-feature-confirmation__section">
            {addedParents.length === 0 && (
              <strong>
                {`Removing "${
                  feature.name
                }" will make the following ${getPluginsStringPluralForm(
                    unusedDependencies,
                )} unused:`}
              </strong>
            )}
            {addedParents.length > 0 && (
              <strong>
                {`...and will make the following ${getPluginsStringPluralForm(
                    unusedDependencies,
                )} unused:`}
              </strong>
            )}
            {unusedDependencies.map((dependency) => (
              <div
                key={dependency.id}
                className="remove-feature-confirmation__list-item"
              >
                <label className="remove-feature-confirmation__label">
                  <Checkbox
                    checked={
                      !excludedFromRemovalFeatures.some(
                          (it) => it.id === dependency.id,
                      )
                    }
                    onChange={({ target }) =>
                      toggleDependencyCheckbox(dependency, target.checked)
                    }
                  />
                  <div className="remove-feature-confirmation__label-text">
                    {dependency.name}
                  </div>
                </label>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="remove-feature-confirmation__footer">
        <Button size="xs" theme="dark" mode="rock" onClick={doRemoveFeature}>
          {featuresNumberToRemove === 1 ?
            `Remove "${feature.name}"` :
            `Remove ${featuresNumberToRemove} plugins`}
        </Button>
      </div>
    </div>
  );
};

RemoveFeatureConfirmation.propTypes = {
  feature: PropTypes.object,
  addedParents: PropTypes.arrayOf(PropTypes.object),
  unusedDependencies: PropTypes.arrayOf(PropTypes.object),
  removeFeature: PropTypes.func,
};

export default RemoveFeatureConfirmation;
