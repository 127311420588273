import './styles/control-panel.css';

import React from 'react';
import KtorGeneratorBlurb, { KtorGeneratorBlurbText } from './KtorGeneratorBlurb';
import { useScroll, useViewport } from '../services/helper';
import GenerateCard from './GenerateCard';
import PropTypes from 'prop-types';
import cn from 'classnames';

const singlePaneSize = 920;
const narrowSize = 1060;
const largeMobileSize = 690;
const headerHeight = 80;
const minHeight = 235;

const ControlPanel = ({ onGenerate, children }) => {
  const { scrollY } = useScroll('kt-gen1');
  const panelHeight = 270 - Math.max(0, scrollY - headerHeight);
  const { width } = useViewport();
  const headerFontSize = width < narrowSize ? 24 : Math.max(28, 14 + Math.max(0, panelHeight) / 10);
  const singlePane = width < singlePaneSize;

  return (
    <>
      <div className="control-panel" style={{ height: Math.max(panelHeight, minHeight) }}>

        <div className={ cn('control-panel__generate', { 'single-pane': singlePane }) } >
          { !singlePane && <KtorGeneratorBlurb headerFontSize={headerFontSize} /> }

          <GenerateCard
            onGenerate={ onGenerate }
            flatten={ panelHeight < 215 }
            squeeze={ width < largeMobileSize }
            tooltip={ singlePane ? <KtorGeneratorBlurbText /> : null }
          />
        </div>

        { children }

      </div>
    </>
  );
};

ControlPanel.propTypes = {
  onGenerate: PropTypes.func,
  children: PropTypes.arrayOf(PropTypes.element),
};

export default ControlPanel;
