import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import './styles/selected-plugins.css';
import { CloseIcon, DownIcon, UpIcon } from '@rescui/icons';
import { getAllTransitiveParents } from '../services/helper';
import { REMOVE_FEATURE } from '../redux/actionTypes';
import { fullFeaturesWithAddedFlag } from './PluginsList';
import { connect } from 'react-redux';
import { Button } from '@rescui/button';
import { Dropdown } from '@rescui/dropdown';
import { Tooltip } from '@rescui/tooltip';
import cn from 'classnames';

const RemovePluginIcon = ({
  plugin,
  removePlugin,
  onHover,
}) => {
  const dependents = useMemo(
      () => getAllTransitiveParents(plugin).filter((parent) => parent.wasAdded),
      [plugin],
  );
  const dependentIds = useMemo(
      () => dependents.map((d) => d.id),
      [dependents],
  );
  const removeIcon = (
    <div
      className="close-icon"
      role="button"
      tabIndex="0"
      aria-label="Remove plugin"
      onClick={() => removePlugin([plugin.id, ...dependentIds])}
      onMouseEnter={onHover ? () => onHover(dependentIds) : undefined}
      onMouseLeave={onHover ? () => onHover([]) : undefined}
    >
      <CloseIcon size="xs" />
    </div>
  );

  return !onHover && dependents?.length > 0 ? (
    <Tooltip className="selected-plugins__remove-tooltip" content={
      <>
        This will also remove:
        <ul className="selected-plugins__remove-list">
          {dependents.map((dependent) =>
            (<li key={dependent.id}>{dependent.name}</li>),
          )}
        </ul>
      </>
    }>
      {removeIcon}
    </Tooltip>
  ) : removeIcon;
};

RemovePluginIcon.propTypes = {
  plugin: PropTypes.object,
  removePlugin: PropTypes.func,
  onHover: PropTypes.func,
};

const SelectedPluginsDropdown = ({
  plugins,
  removePlugin,
}) => {
  const [open, setOpen] = useState(false);
  const [dependents, setDependents] = useState([]);

  return (
    <Dropdown
      trigger={
        <Button
          size="s"
          icon={open ? <UpIcon /> : <DownIcon />}
          iconPosition="right"
          disabled={!plugins.length}
          onClick={() => setOpen(!open)}
        >
          <div>
            <span className="plugins-count">{ plugins.length || 'No' }</span>
            &nbsp;Plugins
          </div>
        </Button>
      }
      renderInTrigger={true}
      isOpen={open}
    >
      { plugins.map((plugin) =>
        <div className="selected-plugins__plugin rs-text-3 rs-text-3_hardness_hard" key={plugin.id}>
          <span className={cn(
              'selected-plugins__plugin-name',
              { 'highlight-for-removal': dependents.includes(plugin.id) },
          )}>
            {plugin.name}
          </span>
          <RemovePluginIcon plugin={plugin} removePlugin={removePlugin} onHover={setDependents} />
        </div>)
      }
    </Dropdown>
  );
};

const SelectedPluginsTags = ({
  plugins,
  removePlugin,
}) => (
  <div className="selected-plugins">
    {plugins.map((plugin) =>
      (
        <div className="selected-plugins__plugin selected-plugins__plugin-tag rs-text-3 rs-text-3_hardness_hard" key={plugin.id}>
          <span>{plugin.name}</span>
          <RemovePluginIcon plugin={plugin} removePlugin={removePlugin} />
        </div>
      ),
    )}
  </div>
);

const SelectedPlugins = ({
  plugins,
  removePlugin,
}) => {
  return plugins.length > 4 ?
    <SelectedPluginsDropdown plugins={plugins} removePlugin={removePlugin} /> :
    <SelectedPluginsTags plugins={plugins} removePlugin={removePlugin} />;
};

const selectedPluginsPropTypes = {
  plugins: PropTypes.arrayOf(PropTypes.object),
  removePlugin: PropTypes.func,
};
SelectedPluginsDropdown.propTypes = selectedPluginsPropTypes;
SelectedPluginsTags.propTypes = selectedPluginsPropTypes;
SelectedPlugins.propTypes = selectedPluginsPropTypes;


const mapDispatchToProps = (dispatch) => ({
  removePlugin: (ids) => dispatch({ type: REMOVE_FEATURE, payload: { ids } }),
});

const mapStateToProps = ({
  features,
  projectConfig,
}) => ({
  plugins: fullFeaturesWithAddedFlag(features, projectConfig.plugins).filter((feature) => feature.wasAdded),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectedPlugins);
