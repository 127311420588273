export default function searchFeatures(search, featuresByCategory, vendorFilter, categoryFilter) {
  const escapeRegex = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const getMatcher = (regexFn, prop) =>
    function matcher(feature) {
      const regex = new RegExp(regexFn(search), 'i');
      if (!feature[prop]) return false;
      return feature[prop].match(regex) !== null;
    };

  const startsInName = getMatcher((s) => `^${escapeRegex(s)}`, 'name');
  const inName = getMatcher(escapeRegex, 'name');
  const inDescription = getMatcher(escapeRegex, 'description');

  return featuresByCategory
      .map((group) => {
        return {
          name: group.name,
          features: group.features.filter((f) =>
            (!vendorFilter || f.vendor?.name === vendorFilter) &&
            (!search || startsInName(f) || inName(f) || inDescription(f)),
          ),
        };
      })
      .filter((category) => category.features.length > 0 && (!categoryFilter || category.name === categoryFilter));
}
