import './styles/plugin-preview.css';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useMemo } from 'react';
import { ThemeProvider } from '@rescui/ui-contexts';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LoadingIcon } from '@rescui/icons';
import { SHOW_FEATURE_DESCRIPTOR, TOGGLE_THEME } from '../redux/actionTypes';
import FeatureDocContent from './FeatureDocContent';
import VendorLink from './VendorLink';
import VcsLink from './VcsLink';
import GenerateFromPreviewCard from './GenerateFromPreviewCard';
import KtorPageHeader from './KtorPageHeader';
import cn from 'classnames';

const PluginPreview = ({
  descriptors,
  fetchFeatureDescriptor,
  toggleTheme,
  theme,
}) => {
  const location = useLocation();
  const { pathname } = location;
  const pluginId = useMemo(
      () => pathname.substring(pathname.lastIndexOf('/') + 1),
      [pathname],
  );
  const feature = useMemo(
      () => descriptors[pluginId],
      [descriptors],
  );
  const { description, usage, options } = feature?.documentation || {};

  useEffect(() => {
    fetchFeatureDescriptor(pluginId);
  }, [pluginId]);

  return (
    <ThemeProvider theme={theme}>
      <div id="kt-gen1" className={cn('plugin-preview', theme)}>
        <KtorPageHeader toggleTheme={toggleTheme} />
        <main>
          {
            feature ?
              (
                <>
                  <GenerateFromPreviewCard
                    theme={theme}
                    plugin={feature}
                  />

                  <h1 className="rs-h1">{feature.name}</h1>

                  <div className="plugin-preview__links">
                    <VcsLink link={feature.vcsLink} />
                    <VendorLink vendor={feature.vendor} />
                  </div>

                  <FeatureDocContent
                    description={description}
                    usage={usage}
                    options={options}
                    theme={theme}
                  />
                </>
              ) : <LoadingIcon theme={theme} />
          }
        </main>
      </div>
    </ThemeProvider>
  );
};

PluginPreview.propTypes = {
  descriptors: PropTypes.object,
  fetchFeatureDescriptor: PropTypes.func,
  toggleTheme: PropTypes.func,
  theme: PropTypes.string,
};


const mapDispatchToProps = (dispatch) => ({
  fetchFeatureDescriptor: (id) => dispatch({ type: SHOW_FEATURE_DESCRIPTOR, payload: { id } }),
  toggleTheme: () => dispatch({ type: TOGGLE_THEME }),
});

const mapStateToProps = ({ selectedFeatureId, descriptors, theme }) => {
  return {
    id: selectedFeatureId,
    theme,
    descriptors: descriptors || {},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PluginPreview);
