import './styles/plugin-item.css';
import React, { useCallback } from 'react';
import cn from 'classnames';
import { useTextStyles } from '@rescui/typography';
import PropTypes from 'prop-types';

const PluginListItem = React.forwardRef(function PluginListItem(
    {
      id,
      isAdded,
      isExpanded,
      isSelected,
      onSelect,
      icon,
      name,
      description,
      trigger,
      expandedContent,
    },
    ref,
) {
  const textCn = useTextStyles();
  const handleClick = useCallback(
      ({ target }) => {
        if (target.nodeName?.toUpperCase() !== 'BUTTON' && onSelect) {
          onSelect(id);
        } else {
          onSelect(null);
        }
      },
      [onSelect],
  );
  return (
    <div>
      <div
        ref={ref}
        className={cn('plugin-item', {
          'plugin-item_selected': isSelected,
          'plugin-item_expanded': isExpanded,
          'plugin-item_was-added': isAdded,
        })}
        onClick={handleClick}
        onTouchStart={handleClick}
      >
        { icon &&
          <div className="plugin-item__icon">
            { icon }
          </div>
        }
        <div className="plugin-item__text-info">
          <div className={cn('plugin-item__name', textCn('rs-text-2', { hardness: 'hard' }))}>
            {name}
          </div>
          <div className={cn('plugin-item__description', 'rs-text-3')}>
            {description}
          </div>
        </div>
        { trigger }
      </div>
      { isExpanded ? expandedContent : [] }
    </div>
  );
});

PluginListItem.propTypes = {
  id: PropTypes.string.isRequired,
  isAdded: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  icon: PropTypes.element,
  name: PropTypes.string.isRequired,
  description: PropTypes.element.isRequired,
  trigger: PropTypes.element.isRequired,
  expandedContent: PropTypes.element.isRequired,
};

export default PluginListItem;
