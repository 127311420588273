import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTextStyles } from '@rescui/typography';
import { useCardCn } from '@rescui/card';
import cn from 'classnames';
import { useGlowHover } from '@rescui/use-glow-hover';
import { Link } from 'react-router-dom';
import { useViewport } from '../services/helper';
import KtorLogo from '../KtorLogo';

const CreateLink = ({ text }) => {
  const textCn = useTextStyles();
  return (
    <span
      className={cn(
          textCn('rs-link', { mode: 'standalone' }),
          'plugin-preview__create-link rs-text-2 rs-text-2_hardness_hard rs-docs-col rs-docs-col-inline',
      )}
    >{ text }</span>
  );
};

CreateLink.propTypes = {
  text: PropTypes.string,
};

const GenerateFromPreviewCard = ({ theme, plugin }) => {
  const textCn = useTextStyles();
  const cardCn = useCardCn();
  const cardRef = useGlowHover({ lightColor: '#6473ff', enableBurst: true });
  const generateLocation = useMemo(
      () => '/s/settings?plugins=' + plugin.id,
      [plugin],
  );
  const { width } = useViewport();

  return (
    <Link to={generateLocation}>
      {
        width > 720 ?
          (
            <div
              className={cn('plugin-preview__generate-button', cardCn({ isClickable: true, theme }))}
              ref={cardRef}
            >
              <div className="plugin-preview__generate-button__header-wrapper">
                <div className="plugin-preview__ktor-logo">
                  <KtorLogo />
                </div>
                <h3 className={cn(textCn('rs-h3'), 'card-1__heading')}>
            Build with Ktor
                </h3>
              </div>
              <p className={cn(textCn('rs-text-2'), 'card-1__text')}>
          Generate a new project using this Ktor extension.
              </p>
              <CreateLink text="Create" />
            </div>
          ) : <CreateLink text="New Ktor Project" />
      }
    </Link>
  );
};

GenerateFromPreviewCard.propTypes = {
  theme: PropTypes.string,
  plugin: PropTypes.object,
};

export default GenerateFromPreviewCard;
