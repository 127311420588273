import { filterByPrefix } from './helper';

/*
 * Generates receipt headers from query params and cookies.
 *
 * This is used for injecting a metadata file into the resulting project, which is
 * read by the IDE.
 */
export function collectReceiptHeaders(initialQuery) {
  try {
    if (!hasAnalyticsStorage()) {
      return false;
    }
    const utmQueryParams = filterByPrefix(initialQuery, 'utm');
    const utmCookieParams = getLastUtmParametersAsObject();
    const utmParams = { ...utmQueryParams, ...utmCookieParams };
    return Object.keys(utmParams).length > 0 ?
      { 'X-Ktor-Receipt': JSON.stringify(utmParams) } :
      {};
  } catch (e) {
    console.warn('Failed to generate receipt headers', e);
    return {};
  }
}

/*
 * Returns true when cookie consent includes analytics storage.
 */
export function hasAnalyticsStorage() {
  // Check if the "cookie_consent" cookie exists
  if (document.cookie.indexOf('cookie_consent') === -1) {
    return false;
  }

  // Get the value of the "cookie_consent" cookie
  const cookies = document.cookie.split(';');
  const cookieVal = cookies.find((cookie) => cookie.trim().startsWith('cookie_consent='));

  // Check if the value includes 'analytics_storage'
  return cookieVal && cookieVal.indexOf('analytics_storage') !== -1;
}

const lastUtmParameterKeys = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'gclid',
];

/*
 * Gets the value of the last_utm_parameters cookie and parses from a | separated string like so:
 *
 * utm_source | utm_medium | utm_campaign | utm_term | utm_content | gclid
 *
 * Returns empty object when the cookie is unavailable or invalid.
 */
function getLastUtmParametersAsObject() {
  const cookies = document.cookie.split(';');
  const utmCookie = cookies.find((cookie) => cookie.trim().startsWith('last_utm_parameters='));

  if (!utmCookie) {
    return {};
  }

  try {
    const rawCookieValue = utmCookie.trim().substring('last_utm_parameters='.length);
    const lastUtmParameterValues = decodeURIComponent(rawCookieValue);

    return parseBarSeparatedValues(lastUtmParameterKeys, lastUtmParameterValues);
  } catch (error) {
    console.warn('Failed to parse last_utm_parameters; returning empty', error);
    return {};
  }
}

/**
 * Parses a bar-separated list of items (i.e., "one|two|three") then assigns each value
 * the corresponding key for the same index, returning a resulting object with said keys.
 *
 * Values of the string "null" are ignored.
 *
 * @param {string[]} keys The expected keys for the resulting object.
 * @param {string} text The bar-separated list as text
 * @return {object} An object with the provided values mapped to the expected keys.
 */
function parseBarSeparatedValues(keys, text) {
  const values = text.split('|').map((item) => item.trim());

  if (values.length !== keys.length) {
    throw new Error(`Incorrect number of values ${values.length}; expected ${keys.length}`);
  }

  return keys.reduce((acc, key, index) => {
    if (values[index] !== 'undefined') {
      acc[key] = values[index];
    }
    return acc;
  }, {});
}
