import {
  CHANGE_PARAMETER_VALUE,
  LOAD_FEATURES,
  START_LOAD_FEATURES,
  UPDATE_SERIALIZATION_MODEL,
} from '../actionTypes';
import fetchApi from '../../services/fetchApi';
import { trackError } from '../../constants/analytics';

export default function refetchFeatureList({ dispatch, getState }) {
  const loadFeatures = (data) => {
    dispatch({ type: LOAD_FEATURES, payload: data });
    dispatch({
      type: UPDATE_SERIALIZATION_MODEL,
      payload: getState().serializationModel.withPlugins(
          data.map(({ xmlId, requiredFeatures }) => ({
            id: xmlId,
            requiredFeatures,
          })),
      ),
    });
  };

  const startLoadFeatures = () => dispatch({ type: START_LOAD_FEATURES });

  return (next) => (action) => {
    if (
      action.type === CHANGE_PARAMETER_VALUE &&
      action.payload &&
      action.payload.name === 'ktorVersion'
    ) {
      startLoadFeatures();
      fetchApi(`/features/${action.payload.value}`)
          .then(loadFeatures)
          .catch(({ status }) =>
            trackError(
                `load-features-on-version-change-${status}`,
                true,
            ),
          );
    }

    return next(action);
  };
}
