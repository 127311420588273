import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import MarkdownIt from 'markdown-it';
import prism from 'markdown-it-prism';
import cn from 'classnames';
import 'prismjs/components/prism-kotlin.min';
import 'prismjs/themes/prism.css';

const HTML_ESCAPE_TEST_RE = /[&<>"]/;
const HTML_ESCAPE_REPLACE_RE = /[&<>"]/g;
const HTML_REPLACEMENTS = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
};

function replaceUnsafeChar(ch) {
  return HTML_REPLACEMENTS[ch];
}

function escapeHtml(str) {
  if (HTML_ESCAPE_TEST_RE.test(str)) {
    return str.replace(HTML_ESCAPE_REPLACE_RE, replaceUnsafeChar);
  }
  return str;
}
export const withRescUI = (theme) => (md) => {
  md = withRescUIStyles(md, theme);
  md = withRescUILinks(md, theme);
  md = withRescUICode(md, theme);
  return md;
};

function withRescUIStyles(md, theme) {
  let isInsideList = false;

  // eslint-disable-next-line camelcase
  md.renderer.rules.heading_open = (tokens, idx) => {
    const token = tokens[idx];
    switch (token.tag) {
      case 'h1':
        return `<h2 class="rs-h2">`;
      case 'h2':
        return `<h3 class="rs-h3">`;
      case 'h3':
        return `<h4 class="rs-h4">`;
      case 'h4':
        return `<h5 class="rs-h4">`;
      default:
    }

    return '';
  };

  // eslint-disable-next-line camelcase
  md.renderer.rules.heading_close = (tokens, idx) => {
    const token = tokens[idx];
    switch (token.tag) {
      case 'h1':
        return `</h2>`;
      case 'h2':
        return `</h3>`;
      case 'h3':
        return `</h4>`;
      case 'h4':
        return `</h5>`;
      default:
    }

    return '';
  };

  // eslint-disable-next-line camelcase
  md.renderer.rules.ordered_list_open = () => {
    isInsideList = true;
    // pad list with 12px
    return `<ol class="rs-text-2 rs-text-2_hardness_hard rs-offset-left-12">`;
  };

  // eslint-disable-next-line camelcase
  md.renderer.rules.ordered_list_close = () => {
    isInsideList = false;
    return `</ol>`;
  };

  // eslint-disable-next-line camelcase
  md.renderer.rules.paragraph_open = () => {
    if (isInsideList) {
      return ``;
    } else {
      return `<p class="rs-text-2 rs-text-2_hardness_hard rs-offset-top-12">`;
    }
  };

  return md;
}

function withRescUILinks(md, theme) {
  // eslint-disable-next-line camelcase
  md.renderer.rules.link_open = (tokens, idx) => {
    const token = tokens[idx];
    const href = token.attrGet('href') || '';
    const title = token.attrGet('title') || '';
    const classes = cn(token.attrGet('class'), 'rs-link', { 'rs-link_theme_dark': theme === 'dark' });

    const titleAttr = title ? ` title="${title}"` : '';
    return `<a class="${classes}" href="${href}"${titleAttr} target="_blank" rel="noopener noreferrer nofollow">`;
  };

  // eslint-disable-next-line camelcase
  md.renderer.rules.link_close = () => '</a>';
  return md;
}

function withRescUICode(md, theme) {
  // eslint-disable-next-line camelcase
  md.renderer.rules.code_inline = (tokens, idx, options, env, slf) => {
    const token = tokens[idx];
    const classes = cn('rs-code', { 'rs-code_theme_dark': theme === 'dark' });
    return `<code class="${classes}" ${slf.renderAttrs(token)}>${escapeHtml(
        token.content,
    )}</code>`;
  };

  // eslint-disable-next-line camelcase
  md.renderer.rules.code_block = (tokens, idx, options, env, slf) => {
    const token = tokens[idx];
    const classes = cn('rs-pre', { 'rs-pre_theme_dark': theme === 'dark' });
    return `<pre class="${classes}" ${slf.renderAttrs(token)}><code>${escapeHtml(
        token.content,
    )}</code></pre>`;
  };

  md.renderer.rules.fence = (tokens, idx, options, env, slf) => {
    const token = tokens[idx];
    const info = token.info ? token.info.trim() : '';
    const langName = info ? info.split(/\s+/g)[0] : '';

    const highlighted =
      (options.highlight ? options.highlight(token.content, langName) : '') ||
      escapeHtml(token.content);
    const classes = cn(
        'rs-pre',
        { 'rs-pre_theme_dark': theme === 'dark' },
        // options.langPrefix + langName,
    );

    return `<pre class="${classes}"><code class="${options.langPrefix + langName}" ${slf.renderAttrs(
        token,
    )}>${highlighted}</code></pre>\n`;
  };

  return md;
}

const markdownWithTheme = (theme) => new MarkdownIt('default', {
  html: true,
  linkify: true,
  breaks: true,
}).use(prism, {
  defaultLanguageForUnspecified: 'kotlin',
  defaultLanguageForUnknown: 'kotlin',
  defaultLanguage: 'kotlin',
}).use(withRescUI(theme));

const md = {
  'light': markdownWithTheme('light'),
  'dark': markdownWithTheme('dark'),
};

const render = (text, theme) => {
  try {
    return md[theme].render(text);
  } catch (e) {
    if (e instanceof TypeError) {
      console.warn(`This is an unexpected require behavior bug in Webpack 4.
      Please check if you specified correct names for languages in markdown.`);
      return '';
    }

    throw e;
  }
};

function MarkdownCode({ code, theme }) {
  const html = useMemo(() => render(code, theme), [code, theme]);

  return (
    <div dangerouslySetInnerHTML={{ __html: html }} />
  );
}

MarkdownCode.propTypes = {
  code: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
};

export default MarkdownCode;
