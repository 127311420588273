import { initialState } from '../redux/reducers';
import localStorage from '../constants/localStorage';

const KEY = 'config';

export const loadState = () => {
  const serializedState = localStorage.getItem(KEY);
  if (!serializedState) {
    return initialState;
  }

  const { projectConfig, previewProject } = JSON.parse(serializedState) || {};
  if (!projectConfig?.hasOwnProperty('artifact')) {
    return initialState;
  }

  return {
    ...initialState,
    projectConfig: {
      ...initialState.projectConfig,
      ...projectConfig,
    },
    previewProject: previewProject || {},
  };
};

export const saveState = ({ projectConfig, previewProject, generatedProject }) => {
  if (generatedProject && generatedProject.blob) {
    localStorage.setItem(KEY, '{}');
  } else {
    localStorage.setItem(KEY, JSON.stringify({
      projectConfig,
      previewProject,
    }));
  }
};
